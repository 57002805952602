import { Col, Container, Row } from "react-bootstrap";
import Helmet from "../components/Helmet";
import Donation from "../components/Donation";

function Donate() {
    return (
        <>
            <Helmet title={'Donate'}></Helmet>
            <Container fluid className="d-flex justify-content-center align-items-center about-banner-container banner-container banner-image-container">
                <div><h1 className="display-2">Donate</h1></div>
            </Container>
            <Container className="mt-5">
                <p className="lead">Uptown &amp; Boogie Healthy Project is a 501(c)3 nonprofit organization that provides access to fresh, nutritious food and bicycle education within two New York City neighborhoods. Our programming creates opportunities for low-income community residents in under-resourced areas to learn how to lead healthier lifestyles through food and fitness.</p>
                <Row>
                    <Col sm={12} md={6}>
                        <p>With your support, we can continue to expand access to fresh food from local farms through our farmer's market and community-supported agriculture program. We can also continue implementing environmental projects, such as our food scrap drop-off site, that promote sustainable waste management practices and reduce greenhouse gas emissions.</p>
                    </Col>
                    <Col sm={12} md={6}>
                        <p>Our youth crew program provides job training and leadership development for young people in our community. By supporting this program, you are helping to create opportunities for the next generation to build a healthier community.</p>
                    </Col>
                </Row>
            </Container>
            
            <Donation />

            <Container fluid className="mt-md-5 text-center">
                <p className="h3">Thank you for considering a donation to the Uptown &amp; Boogie Healthy Project. <br/>Together, we can build a healthier future for our community.</p>
            </Container>

        </>
    );
}

export default Donate;
