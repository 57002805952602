import { Button, Col, Container, Row } from "react-bootstrap";
import Benefits from "../components/Benefits";
import ReusableBags from "../components/ReusableBags";
import Volunteering from "../components/Volunteering";
import Helmet from "../components/Helmet";
import Donation from "../components/Donation";

function FarmersMarket() {
    return (
        <>
            <Helmet title={'Farmers Market'}></Helmet>
            <Container fluid className="d-flex justify-content-center align-items-center farmers-market-banner-container banner-container banner-image-container">
                <div><h1 className="display-2">Farmers Market</h1></div>
            </Container>
            <Container fluid className="my-5">
                <Row>
                    <Col xs={12} md={6} className="bg-light p-md-4">
                        <h3>Pelham Parkway, The Bronx</h3>
                        <p>The farmers market is near the Brady Playground which has accessible play elements. <strong><a href="https://www.nycgovparks.org/parks/bronx-park" rel="noopener noreferrer" target="_blank" title="Learn more">Learn more »</a></strong></p>
                        <Container className='d-grid gap-1 d-md-block d-md-flex justify-content-md-between mb-5'>
                            <Button className="btn" href='https://ubhealthyproject.org/apply' variant="warning btn-lg">Apply to become a vendor</Button>
                            <Button className="btn" href='https://datausa.io/profile/geo/pelham-parkway-morris-park-laconia-puma-ny' target="_blank" variant="warning btn-lg">Schedule Appointment</Button>
                        </Container>
                        <Row className="align-items-start">
                            <Col xs={12} md={6}>
                                <strong>Address: </strong><br />
                                <address>2045 Bronx Park E, Bronx, NY 10462</address>
                                Bronx Park East &amp; Brady Avenue <br /><em><a href="https://g.page/brady-playground?share" rel="noopener noreferrer" target="_blank">Google Map</a></em>
                            </Col>
                            <Col xs={12} md={6}>
                                <strong>Distribution Day: </strong><br />
                                <ul className="list-unstyled">
                                    <li>Sundays / 9am - 2pm</li>
                                    <li>June - November (22 weeks)</li>
                                </ul>
                            </Col>
                            <Col xs={12} md={12} className="p-md-4">
                                <p><b>We are welcoming additional vendors to provide the below:</b></p>
                                <ul className="">
                                    <li>Vegetables, Herbs and Fruits</li>
                                    <li>Mushrooms</li>
                                    <li>Meat, Seafood, poultry</li>
                                    <li>Cheese and all dairy products</li>
                                    <li>Eggs</li>
                                    <li>Bread and Baked Goods</li>
                                    <li>Condiments type products</li>
                                    <li>Maple/honey/cider/juices</li>
                                    <li>Plants and seedlings that bear food</li>
                                </ul>
                            </Col>

                        </Row>
                    </Col>
                    <Col xs={12} md={6}>
                        <h3>A Haven for Fresh, Local Produce</h3>
                        <p>The Bronx Park East (BxPE) Farmers Market takes pride in serving the Pelham Parkway community with the vibrant farmers' market, which provides fresh, locally grown produce. Stroll through colors and smells that greet you and engage with passionate vendors showcasing their fruits, vegetables, herbs, eggs, and various wholesome goods.</p>
                        <p>Beyond the quantity of fresh produce, discover an array of locally crafted delicacies, including honey, baked goods, and culinary creations that tease the taste buds. Each item reflects the passion and expertise of local small business owners, adding a touch of homegrown goodness to your shopping experience.</p>
                        <p>Whether you're a seasoned chef seeking culinary inspiration or a health-conscious individual seeking to feed your body, delight your senses, and nurture your well-being.</p>
                        <p><strong><a href="https://www.nycgovparks.org/parks/bronx-park/history" rel="noopener noreferrer" target="_blank" title="Learn more"> History of Bronx Park »</a></strong></p>
                    </Col>
                </Row>
            </Container>
            <Container className="text-center">
                <h2 className="display-1">Local Farmers</h2>
                <Row className="row-cols-sm-2 g-2 g-lg-3">
                    <Col>
                        <div className="vendor-img-wrapper mb-3">
                            <a href="https://www.grandpafarm.com/" rel="noopener noreferrer" target="_blank" className="vendor-img grandpafarm-img" tabindex="0"><span className="d-none">Grandpa Farm</span></a>
                        </div>
                        <h5>Grandpa Farm</h5>
                        <Container className="mt-3 p-0 text-left">
                            <p>The farm produce vegetable, herbs and more.</p>
                        </Container>
                    </Col>
                    <Col>
                        <div className="vendor-img-wrapper mb-3">
                            <span className="vendor-img thehouseofgreens-img" tabindex="0"><span className="d-none">The House of Greens</span></span>
                        </div>
                        <h5>The House of Greens</h5>
                        <Container className="mt-3 p-0 text-left">
                            <p>The farm produce vegetable, herbs and more.</p>
                        </Container>
                    </Col>
                    <Col>
                        <div className="vendor-img-wrapper mb-3">
                            <span className="vendor-img perexmarket-img" tabindex="0"><span className="d-none">Perez Market</span></span>
                        </div>
                        <h5>Perez Market</h5>
                        <Container className="mt-3 p-0 text-left">
                            <p>Farmer produces fruits, seedlings, flowers and honey</p>
                        </Container>
                    </Col>
                    <Col>
                        <div className="vendor-img-wrapper mb-3">
                            <span className="vendor-img miohmy-img" tabindex="0"><span className="d-none">Mi Oh My Hydroponic Farms</span></span>
                        </div>
                        <h5>Mi Oh My Hydroponic Farms</h5>
                        <Container className="mt-3 p-0 text-left">
                            <p>The farm produce mushrooms and microgreens.</p>
                        </Container>
                    </Col>
                </Row>
            </Container>
            <Benefits />
            <Container>
                <Volunteering />
            </Container>
            <Container fluid id="volunteering">
                <div className="text-center">
                    <h2 className="display-4">Donation</h2>
                </div>
                <Donation />
            </Container>
            <ReusableBags />
        </>
    );
}

export default FarmersMarket;
