import { Button, Col, Container, Row } from "react-bootstrap";
import Helmet from "../components/Helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

function Composting() {
    return (
        <>
            <Helmet title={'Food Scraps Drop-off'}></Helmet>
            <Container fluid className="d-flex justify-content-center align-items-center waste-banner-container banner-container banner-image-container">
                <div><h1 className="display-2">Food Scraps Drop-off</h1></div>
            </Container>

            <Container fluid className="bg-light p-md-3 text-center">
                <p className="col-md-6 mx-auto">Your donation supports the food scraps drop-off site and fund compost micro-hauling services at Bronx Park East Farmers Market &amp; CSA in The Bronx!</p>
                <p>
                    <Button href='https://donate.stripe.com/aEU6rG3USf24eo84gK' size='lg' variant="warning" rel="noopener noreferrer" target="_blank">Donate</Button>
                </p>
            </Container>

            <Container>
                <Row>
                    <Col sm={12} md={6}>
                        <h2>What is it?</h2>
                        <p>Composting is a process of recycling a mixture of organic waste-including food scraps and yard clippings-and converting them into nutrient-dense soil</p>
                    </Col>
                    <Col sm={12} md={6}>
                        <h2>Why Compost?</h2>
                        <ul>
                            <li>Reduces Landfill Waste</li>
                            <li>Decrease Greenhouse Gas Emissions</li>
                            <li>Decrease Water &amp; Soil Pollution</li>
                            <li>Prevents Community Exposure to Harmful Chemicals</li>
                        </ul>
                    </Col>
                </Row>
            </Container>

            <Container>
                <Row>
                    <Col sm={12} md={6}>
                        <h2>What to Drop-Off?</h2>
                        <ul>
                            <li>Fruit &amp; Vegetable Scraps and Peels</li>
                            <li>Rotten Fruits &amp; Vegetables</li>
                            <li>Eggshells, Nutshells &amp; Chopped Seeds</li>
                            <li>Coffee Grounds</li>
                            <li>Yard Waste (grass, leaves, plant clippings)</li>
                        </ul>
                    </Col>
                    <Col sm={12} md={6}>
                        <h2>What to Leave Home?</h2>
                        <ul>
                            <li>Dairy</li>
                            <li>Meat &amp; Seafood</li>
                            <li>Baked Goods</li>
                            <li>Large Branches</li>
                            <li>Ash &amp; Charcoal</li>
                            <li>Walnuts</li>
                        </ul>
                    </Col>
                </Row>
            </Container>


            <Container>
                <Row>
                    <Col sm={12} md={6}>
                        <h3>Drop Off Location</h3>
                        <ul className="list-unstyled">
                            <li>
                                Bronx Park near <em>Brady Playground</em>
                            </li>
                            <li><address>
                                2045 Bronx Pk E, Bronx, NY 10462</address>
                                <a href="https://g.page/brady-playground?share" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faMapMarkerAlt} /> Bronx Park East &amp; Brady Avenue</a>
                            </li>
                            <li>
                                <a href="tel:+13473917189">+1-347-391-7189</a><br />
                                <a href="mailto:bronx@ubhealthyproject.org?subject=BxParkEastCSA&body = Message">bronx@ubhealthyproject.org</a>
                            </li>
                        </ul>
                    </Col>
                    <Col sm={12} md={6}>
                        <h3>Processing Location</h3>
                        <ul className="list-unstyled">
                            <li>
                            Synergi Urban Garden 2.0
                            </li>
                            <li><address>
                            1211 Hoe Ave, Bronx, NY 10459</address>
                                <a href="https://maps.app.goo.gl/ZXaC5U1heYgPKtGt5" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faMapMarkerAlt} />  Hoe Ave, Bronx</a>
                            </li>
                            <li>
                                <a href="tel:+13473917189">+1-347-391-7189</a><br />
                                <a href="mailto:bronx@ubhealthyproject.org?subject=BxParkEastCSA&body = Message">bronx@ubhealthyproject.org</a>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>

            <Container>
                <Row className="align-items-center">
                    <Col sm={12} md={5} className="mb-3"><img className="img-fluid" src={`${process.env.PUBLIC_URL}/assets/composting/food-scraps.jpg`} alt="Collected Food Scraps" /></Col>
                    <Col sm={12} md={7}>
                        <h2 className="text-center">Bronx Compost Drop-Off</h2>
                        <p>We are thrilled to announce our initiative launching from <strong>Sunday, June 2, to November 24, 2024</strong>. We aim to establish a full-season food scrap drop-off site at the Bronx Park East Farmers' Market. We will process the organic waste collected by collaborating with GreenFeen OrganiX (micro-hauler) and Synergi Urban Garden 2.0 (community garden). Additionally, we are excited to offer Kids $2 for each compost drop-off, enhancing our existing program and empowering children to increase their spending power at the farmers' market.</p>
                        <h4>Reducing Food Waste</h4>
                        <p>With the success of our October 2022 4-week compost drop-off pilot at Bronx Park East Farmers Market, we recognize the need for accessible drop-off sites in The Bronx. Due to limited funding in 2022, we're thrilled to share that Uptown &amp; Boogie Healthy Project will establish a full-season (June to November) food scrap drop-off site at Bronx Park East Farmers Market.</p>
                    </Col>
                </Row>
            </Container>
        </>
    );

}

export default Composting;
