import { Col, Container, Row, Button } from "react-bootstrap"

function Donation() {
    return (
        <>
            <Container className="mt-md-3 text-center bg-light pt-3">
                <p className="col-8 mx-auto">You can make a one-time donation or become a monthly donor to help sustain our programming throughout the year. <br/>No matter how small, every gift makes a difference in our ability to create a healthier community.</p>
                <Row className="bg-warning d-md-flex justify-content-evenly align-self-stretch py-4">
                    <Col sm={12} md={5} className="bg-light p-3">
                        <p>Increase your impact with monthly contributions.</p>
                        <Button href="https://donate.stripe.com/aEU6rG3USf24eo84gK" target="_blank" size="lg" variant="warning">Donate Monthly</Button>
                    </Col>
                    <Col sm={12} md={5} className="bg-light p-3">
                        <p>Make a one-time contribution today!</p>
                        <Button href="https://donate.stripe.com/aEU6rG3USf24eo84gK" target="_blank" size="lg" variant="outline-warning">Donate Today!</Button>
                    </Col>
                </Row>
            </Container>

        </>
    )
}
export default Donation