import { Button, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faShoppingBag, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import Helmet from "../components/Helmet";

function Membership() {
    return (
        <>
            <Helmet title={'CSA Membership'}></Helmet>
            <Container fluid className="d-flex justify-content-center align-items-center default-banner-container banner-container banner-image-container">
                <div><h1 className="display-2">CSA Membership</h1></div>
            </Container>
            <Container className="my-5">
                <Row>
                    <Col xs={12} md={6}>
                        <h3>Who runs Bronx Park East CSA?</h3>
                        <p>The CSA is always all-member volunteer run. Each season, members agree to volunteer their time to help with share distributions at Bronx Park East. In addition to this, there is a core team of volunteers that help with event planning, recruiting new CSA members through marketing and promotion, manage finances, and communicate with our farmers and partners year-round!</p>
                    </Col>
                    <Col xs={12} md={6}>
                        <h3>What is the Member agreement?</h3>
                        <p>Members understand that they share in the risks and reward of the growing season. No two seasons are alike, and the abundance experienced one season might be different than the next. Once your share is paid for, there are <strong>no refunds or date change</strong> due to inability to pick-up, vacation schedule, or for any other reason. <br />Please be an understanding and courteous member in our community.</p>
                    </Col>
                    <Col xs={12} md={6}>
                        <h3>How many members are needed for the CSA program?</h3>
                        <p>We need at least <strong>20 members</strong> for each farm season to begin the CSA program.</p>
                    </Col>
                    <Col xs={12} md={6}>
                        <h3>Can I join as a single individual?</h3>
                        <p>Yes, individuals can join and share their share with family, friends and neighbors.</p>
                    </Col>
                    <Col xs={12} md={6}>
                        <h3>Can I use SNAP/EBT/P-EBT to pay for seasonal shares?</h3>
                        <p>Yes, individuals can use their benefits towards seasonal shares in-person at ALL distribution locations.</p>
                        <p><strong>Half Off Farm Box:</strong> New Yorkers who participate in SNAP receive a pre-assembled box of locally grown farm fresh vegetables for half off the price of our full box. <strong>Cost: $11</strong></p>
                        <p className="text-center"><Button href="/snap" size="lg" variant="warning">Learn more <FontAwesomeIcon icon={faInfoCircle} /></Button>{''} <Button href="https://forms.gle/t9KPz3UwcceegyAaA" size="lg" variant="outline-success">Pre-Order (SNAP/EBT) <FontAwesomeIcon icon={faShoppingBag} /></Button>{''}</p>
                        <p>Please contact us directly to learn more at <a href="tel:+13473917189">+1-347-391-7189</a> or <a href="mailto:bronx@ubhealthyproject.org?subject = BxParkEastCSA&body = Message">bronx@ubhealthyproject.org</a></p>
                    </Col>
                    <Col xs={12} md={6}>
                        <h3>When does enrollment begin?</h3>
                        <p>Enrollment payment plans begins now. Contact us directly to learn more at <a href="tel:+13473917189">+1-347-391-7189</a> or <a href="mailto:bronx@ubhealthyproject.org?subject = BxParkEastCSA&body = Message">bronx@ubhealthyproject.org</a></p>
                        <h3>When does the CSA season begin?</h3>
                        <p><strong>Bronx</strong> Sundays, June - November</p>
                    </Col>
                    <Col xs={12} md={6}>
                        <h3>Where are the distribution sites?</h3>
                        <strong>Bronx Park East (Pelham Parkway)</strong><br />
                        Sundays (June to November) / 9AM - 4pm<br />
                        <address>
                            <a href="https://g.page/ben-abrams-playground" rel="noopener noreferrer" target="_blank">Bronx Park East &amp; Brady Avenue <br />
                                The Bronx, NY 10462</a>
                        </address>
                    </Col>
                    <Col xs={12} md={6}>
                        <h3>Can someone else pickup my shares?</h3>
                        <p>Yes, if you are unable to pickup your share please coordinate with a fellow member, friends, family or neighbor to have them pick-up your share. </p>
                        <p>Please contact us directly to provide proxy's name for pick-up at <a href="tel:+13473917189">+1-347-391-7189</a> or <a href="mailto:bronx@ubhealthyproject.org?subject = BxParkEastCSA&body = Message">bronx@ubhealthyproject.org</a></p>
                    </Col>
                    <Col xs={12} md={6}>
                        <h3>What happens if I can't pick-up my share?</h3>
                        <p>We <strong>do not offer refunds</strong> for missed pick-ups. Your share will be offered to nearby community food fridges and residents.</p>
                    </Col>
                    <Col xs={12} md={6}>
                        <h3>Where to make and shop for seasonal membership?</h3>
                        <div className="text-center">
                            <h3 className='lead'>Summer/Fall 2024</h3>
                            <Button variant="outline-success" size="lg" href='/csa#enrollment' className='d-block'>Order Now <span className="text-neon-green ml-2 d-inline"><FontAwesomeIcon icon={faShoppingCart} /></span></Button>
                            <p><strong>Seasonal Membership and Free-Range Eggs</strong></p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );

}

export default Membership;
