import { Accordion, Button, Col, Container, Row } from "react-bootstrap";

import Helmet from "../components/Helmet";
import Donation from "../components/Donation";

function PopKidsClub() {
    return (
        <>
            <Helmet title={'POP Kids Club'}></Helmet>
            <Container fluid className="d-flex justify-content-center align-items-center kids-banner-container banner-container banner-image-container">
                <div><h1 className="display-2">POP Kids Club</h1></div>
            </Container>
            <Container className="mt-5">
                <p className="lead">Welcome to the Power of Produce (PoP) Kids Club program webpage! We are excited to introduce you to this empowering initiative encouraging children ages 4 to 13 to choose healthy food. The PoP Kids Club is a <b>FREE</b> program held <b>every Sunday from June to November</b>, from 9am to 4pm at BxPE Market.</p>
                <Row>
                    <Col sm={12} md={6}><p>During the program, children who participate will receive <u> at least $2 in market currency (POP Tokens)</u> each week to spend on fresh fruits and vegetables at the market. Not only will they have the opportunity to choose nutritious and delicious produce, but they will also collect a stamp on their POP Passport at each visit. </p></Col>
                    <Col sm={12} md={6}><p>We believe every child should have access to fresh and nutritious food, so we are committed to making the Power of Produce Kids Club a permanent program across all our farm programs. To achieve this goal, we kindly ask for your support through a donation. Your contribution will directly impact the lives of children in our community, fostering a love for healthy eating and empowering them to make positive food choices.</p></Col>
                </Row>
                <Container fluid className="text-center mb-4 bg-light py-5">
                    <h2>Join Today!</h2>
                    <p className="lead">
                        Together, we can create a healthier future for our youth!
                    </p>
                    <p><Button href='https://ubhealthyproject.org/popkidsclub/' rel="noopener noreferrer" target="_blank" size="lg" variant="success">Register</Button></p>
                </Container>
            </Container>
            <Container className="mb-4">
                <Accordion defaultActiveKey="0" flush>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><span className="text-center h2 w-100">History of POP Club</span></Accordion.Header>
                        <Accordion.Body>
                            <Row>
                                <Col sm={12} md={4}><iframe width="100%" height="100%" src="https://www.youtube.com/embed/9uAcjjUBGBc?start=19" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></Col>
                                <Col sm={12} md={8}><p>The Power of Produce (POP) program started in May 2011 at the Oregon City Farmers Market in Oregon City, Oregon. It was created by the market manager, Jackie Hammond-Williams in response to a grant offered by Clackamas County, Oregon for programs that improve the community's health. The POP Club brought together families and farmers around fresh produce at farmers markets.</p>
                                    <h3>The program's mission was three-fold:</h3>
                                    <ol>
                                        <li>Empower children to make healthy food choices</li>
                                        <li>Strengthen and sustain healthy communities through supporting farmers and cultivating future farmers market supporters</li>
                                        <li>Expand farmers markets from a retail location into a place where children can try new foods and learn about healthy eating.</li>
                                    </ol>
                                </Col>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Container>
            <Container className="mt-5 text-center">
                <p className="lead">Thank you for your support in making the Power of Produce Kids Club a permanent program that benefits children in our community. Together, we can create a healthier future for our youth!</p>
            </Container>

            <Donation />
        </>
    );
}

export default PopKidsClub;
