import { Container } from 'react-bootstrap';
import Helmet from '../components/Helmet';

function NotFound() {
    return (
        <>
            <Helmet title={'404 Not Found'}></Helmet>
            <Container fluid className="d-flex justify-content-center align-items-center default-banner-container banner-container banner-image-container">
                <div><h1 className="display-2">404 Not Found</h1></div>
            </Container>
            <Container className="text-center p-5">
                <h2 className="display-2">Oops!</h2>
                <p>Sorry, an error has occurred, Requested page not found!</p>
                <p><a href='/'>Take me home</a></p>
            </Container>
        </>
    )
}
export default NotFound;