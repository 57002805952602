import productCSA02 from "./img/placeholder/csa-medium.jpg";
import productCSA03 from "./img/placeholder/csa-large.jpg";
import productCSA04 from "./img/placeholder/csa-weekly.jpg";
import productCSA05 from "./img/placeholder/csa-large-seasonal.jpg";
import productCSA06 from "./img/placeholder/csa-medium-seasonal.jpg";
import productVendor01 from "./img/placeholder/vendor-weekly.jpg";
import productVendor02 from "./img/placeholder/vendor-monthly.jpg";
import productImg01 from "./img/placeholder/profile-default-thumbnail_2.jpg";
import productImg02 from "./img/placeholder/profile-default-thumbnail.jpg";
import productImg03 from "./img/placeholder/eggs.jpg";

/*
// Test mode
const productsArray = [
    {
        id: "price_1M2SjTFnIA3vBryVC8QMFJao",
        stripeId: "prod_Mm0kBrRHhlL3VZ",
        title: "Yellow Adult T-Shirt (small)",
        price: 25.00,
        category: "clothing",
        imgUrl: productImg01,
        shortDesc:
            "Yellow Adult T-Shirt",
        description:
            "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> <p>Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!</p>",
    },
    {
        id: "price_1M2SiuFnIA3vBryVhyttgagK",
        stripeId: "prod_Mm0kcmcrqmBKST",
        title: "Yellow Adult T-Shirt (med)",
        price: 25.00,
        category: "clothing",
        imgUrl: productImg02,
        shortDesc:
            "Yellow Adult T-Shirt",
        description:
            "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> <p>Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!</p>",
    }, 
]
*/

// Live mode
const productsArray = [
    {
        id: "price_1M2SvAFnIA3vBryVyZVl7PwS",
        stripeId: "prod_Mm0xAR55LkB9vH",
        title: "Yellow Adult T-Shirt (small)",
        price: 25.00,
        category: "clothing",
        imgUrl: productImg01,
        shortDesc:
            "Yellow Adult T-Shirt",
        description:
            "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> <p>Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!</p>",
    },
    {
        id: "price_1M2SvUFnIA3vBryV1xJnfvMF",
        stripeId: "prod_Mm0xbBrrn0yrTT",
        title: "Yellow Adult T-Shirt (med)",
        price: 25.00,
        category: "clothing",
        imgUrl: productImg02,
        shortDesc:
            "Yellow Adult T-Shirt",
        description:
            "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> <p>Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!</p>",
    },
    {
        id: "price_1M2SvtFnIA3vBryVHID685Xq",
        stripeId: "prod_Mm0xbUt0eT5H0I",
        title: "Yellow Adult T-Shirt (lg)",
        price: 25.00,
        category: "clothing",
        imgUrl: productImg03,
        shortDesc:
            "Yellow Adult T-Shirt",
        description:
            "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> <p>Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!</p>",
    },
    {
        id: 'price_1MdxByFnIA3vBryVfvovYABr',
        stripeId: "prod_NOkhdHOkLifOjt",
        title: "Weekly",
        productSize: "medium",
        imgUrl: productCSA04,
        category: "csa",
        price: 23,
        shortDesc:
            "Weekly membership of 6 various produce types",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    },
    {
        id: "price_1M8zXOFnIA3vBryVhRWhsitE",
        stripeId: "prod_Msl3xhlasdQf1n",
        title: "Monthly",
        productSize: "medium",
        imgUrl: productCSA02,
        category: "csa",
        price: 92,
        shortDesc:
            "Monthly membership of 6 various produce types",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    },
    {
        id: 'price_1M8zc9FnIA3vBryV5MyPqYj9',
        stripeId: "prod_Msl87rTuNOnbGI",
        title: "Season",
        productSize: "medium",
        imgUrl: productCSA06,
        category: "csa",
        price: 506,
        shortDesc:
            "Full payment for 22 week season from June - Nov (6 various types of seasonal farm fresh produce locally grown per week)",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    },
    {
        id: "price_1M8zZ2FnIA3vBryV27fxAXOJ",
        stripeId: "prod_Msl5mjTKCG2qWn",
        title: "Monthly",
        productSize: "large",
        imgUrl: productCSA03,
        category: "csa",
        price: 144.00,
        shortDesc:
            "Monthly membership of 12 various produce types",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    },
    {
        id: "price_1M8zdRFnIA3vBryVVztwJPmT",
        stripeId: "prod_Msl9IbLBsN4xjj",
        title: "Season",
        productSize: "large",
        imgUrl: productCSA05,
        category: "csa",
        price: 792.00,
        shortDesc:
            "Full payment for 22 week season from June - Nov (10 various types of seasonal farm fresh produce locally grown per week)",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    },
    {
        id: "price_1LAAbhFnIA3vBryV9Z27WcB5",
        stripeId: "prod_Oaa4kVyuAhO4NQ",
        title: "Weekly Eggs",
        productSize: "dozen",
        imgUrl: productImg03,
        category: "addon",
        price: 6,
        shortDesc:
            "a cart of eggs per week",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    },
    {
        id: "price_1LAAZRFnIA3vBryVIEeRptX4",
        stripeId: "prod_LruO3DMLNqs7VQ",
        title: "Montly Eggs",
        productSize: "dozen",
        imgUrl: productImg03,
        category: "addon",
        price: 22,
        shortDesc:
            "a cart of eggs per month",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    },
    {
        id: "price_1MhIJdFnIA3vBryVK5UE5PiO",
        stripeId: "prod_NSCjRgYzoNqpXQ",
        title: "Full Season Eggs",
        productSize: "dozen",
        imgUrl: productImg03,
        category: "addon",
        price: 110,
        shortDesc:
            "a cart of eggs for 22 weeks (Full Season)",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    },
    {
        id: "price_1MZ7HmFnIA3vBryVWnQsZepS",
        stripeId: "prod_NJknHT2Yivto9t",
        title: "Weekly",
        productSize: null,
        imgUrl: productVendor01,
        category: "vendor",
        price: 70,
        shortDesc:
            "Vendors fee for bi-weekly minimum; $35 per week.",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    },
    {
        id: "price_1MZ7J2FnIA3vBryVxUh4KZB4",
        stripeId: "prod_NJkoFXLfJZsr2Q",
        title: "Monthly",
        productSize: null,
        imgUrl: productVendor02,
        category: "vendor",
        price: 140,
        shortDesc:
            "Vendors fee for 4 week minimum; $35 per week",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio nostrum accusantium iste, voluptas cumque provident! Consequatur officiis animi rem tempore voluptate cumque hic similique aperiam ut consectetur distinctio repudiandae quia quam quos, quas illo, iusto, necessitatibus odio veniam exercitationem quis voluptatibus debitis laboriosam! Esse debitis obcaecati blanditiis at impedit quibusdam!",
    },

]

function getProductData(id) {
    let productData = productsArray.find(product => product.id === id);
    // console.log(productData);

    if (productData === undefined) {
        console.log("Product data does not exist for ID: " + id);
        return undefined;
    }
    return productData;
}

export { productsArray, getProductData };