import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { auth } from "../../firebase/config";

function Profile() {
    const [user, setUser] = useState(null)
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(auth.currentUser);
            } else {
                // User is signed out
            }
        });
    }, [])
    return (
        <>
            <Container>
                <h1>Profile</h1>
                {user ?
                    <>
                        <p>{user.displayName}</p>
                    </>
                    :
                    <p>Welcome Back!</p>
                }
            </Container>
        </>
    )
}

export default Profile;