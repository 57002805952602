import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Card, CardGroup, Button, Col, Container, Row } from "react-bootstrap";
import { faBowlFood, faPeopleCarryBox, faTractor } from "@fortawesome/free-solid-svg-icons";
import Helmet from "../components/Helmet";

function Landing() {

    return (
        <>
            <Helmet title={'Welcome'}></Helmet>
            <Container fluid className='main-home'>
                <CardGroup className='text-center px-md-5'>
                    <Card className='border-0 shadow-lg pt-5 mb-5 me-md-4 bg-body rounded'>
                        <Card.Header className='border-0 bg-transparent text-success'><FontAwesomeIcon icon={faTractor} size="4x" /></Card.Header>
                        <Card.Body>
                            <Card.Title>Farmers' Market</Card.Title>
                            <Card.Text>
                                We are excited to bring locally grown produce sold directly by farmers to consumers on <br /><strong>Sundays, Jun. 2 - Nov. 24, 2024 <br />9AM to 4PM</strong>
                            </Card.Text>
                            <Button href='/farmersmarket' size="lg" variant="light">Learn More</Button>
                        </Card.Body>
                        <Card.Img variant="bottom" src={`${process.env.PUBLIC_URL}/assets/landing/veggie-vendors.png`} />
                    </Card>
                    <Card className='border-0 shadow-lg pt-5 mb-5 me-md-4 bg-body rounded'>
                        <Card.Header className='border-0 bg-transparent text-success'><FontAwesomeIcon icon={faPeopleCarryBox} size="4x" /></Card.Header>
                        <Card.Body>
                            <Card.Title>Vendor Application</Card.Title>
                            <Card.Text>
                                We are gearing up for a fantastic 2024 season, and we're searching for passionate vendors to join our community! ‍‍‍
                            </Card.Text>
                            <Button href='https://ubhealthyproject.org/apply' size="lg" variant="warning">Become a Vendor</Button>
                        </Card.Body>
                        <Card.Img variant="bottom" src={`${process.env.PUBLIC_URL}/assets/landing/bronx-sauce.png`} />
                    </Card>
                    <Card className='border-0 shadow-lg pt-5 mb-5 bg-body rounded'>
                        <Card.Header className='border-0 bg-transparent text-success'><FontAwesomeIcon icon={faBowlFood} size="4x" /></Card.Header>
                        <Card.Body>
                            <Card.Title>Grab a Farm Box</Card.Title>
                            <Card.Text>
                                The community supported agriculture (CSA) program is a perfect way to eat seasonally produce and support your local food system.
                            </Card.Text>
                            <Button href='/csa' size="lg" variant="success">Join Today!</Button>
                        </Card.Body>
                        <Card.Img variant="bottom" src={`${process.env.PUBLIC_URL}/assets/landing/veggie-bag.png`} />
                    </Card>
                </CardGroup>
            </Container>

            <Container className="mb-5">
                <Row className="info-container justify-content-center align-items-center">
                    <Col sm={12} md={9}>
                        <div className="mt-5 my-3">
                            <h5>SINCE 2021</h5>
                            <h3 className="title text-dark">Bronx Park East Farmers Market</h3>
                        </div>
                    </Col>
                    <Col sm={12} md={3}>
                        <img className="img-fluid w-100" src={`${process.env.PUBLIC_URL}/assets/landing/salad-bowl.png`} loading="lazy" alt="6 various farm produce" />
                    </Col>
                    <Col sm={12} md={12}>
                        <div className="my-3">
                            <p>The program as a way to promote wellness initiatives, raise awareness about health issues, and generally show the community we care about their well being in Pelham Parkway, The Bronx.</p>
                            <p>We access farm fresh food from local farms in the Tri-state area to service the Pelham Park neighborhood from Bronx Park, Harlem from St Nicholas Miracle Garden (community garden) and St Nicholas Park with tailored community outreach and engagement strategy dedicated to nutrition equity. Community residents can utilize nutritional benefits to purchase healthy foods at both locations which will run annually from June to November.</p>
                            <p><a className="h6" href="/about">Know More <em className="text-success">About Us</em></a></p>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container fluid className="p-md-5 bg-light">
                <Row className="justify-content-center align-items-start">
                    <Col sm={12} md={8}>
                        <h2>Bronx Compost Drop-Off</h2>
                        <p>We are thrilled to announce our initiative launching from <strong>Sunday, June 2, to November 24, 2024</strong>. We aim to establish a full-season food scrap drop-off site at the Bronx Park East Farmers' Market. We will process the organic waste collected by collaborating with EarthKind Compost (micro-hauler) and Morning Glory (community garden). Additionally, we are excited to offer POP Kids $2 for each compost drop-off, enhancing our existing program and empowering children to increase their spending power at the farmers' market.</p>
                    </Col>
                    <Col sm={12} md={4}><img className="img-fluid rounded-circle" src={`${process.env.PUBLIC_URL}/assets/composting/food-scraps.jpg`} loading="lazy" alt="Bronx Compost Drop-off" /></Col>
                </Row>
                <h3>Worm Bin Project</h3>
                        <p>Building an indoor worm bin to create compost for home plants is essential for several reasons:</p>
                        <CardGroup className="mb-5">
                            <Card className="border-0 bg-transparent">
                                <Card.Body>
                                    <Card.Text>It provides a sustainable solution for managing organic waste within the home, reducing waste sent to landfills.</Card.Text>
                                </Card.Body>
                            </Card>
                            <Card className="border-0 bg-transparent">
                                <Card.Body>
                                    <Card.Text>The compost produced by the worm bin is rich in nutrients, enhancing soil health and promoting plant growth. Homeowners can grow healthier plants by recycling kitchen scraps into nutrient-rich compost while minimizing environmental impact.</Card.Text>
                                </Card.Body>
                            </Card>
                            <Card className="border-0 bg-transparent">
                                <Card.Body>
                                    <Card.Text>Maintaining an indoor worm bin offers a hands-on learning experience. It allows individuals to witness the natural decomposition process and worms' beneficial role in nutrient cycling.</Card.Text>
                                </Card.Body>
                            </Card>
                        </CardGroup>
                        <p className="text-center"><Button href='https://airtable.com/appoACR8f5ok128z7/pagcnY4Ufvhk963vp/form' rel="noopener noreferrer" target="_blank" size="lg" variant="success">Join the Project!</Button></p>
            </Container>

            <Container fluid className="p-md-5 bg-light">
                <Row className="justify-content-center align-items-center">
                    <Col sm={12} md={4}><img className="img-fluid rounded-circle" src={`${process.env.PUBLIC_URL}/assets/landing/kid-chef.jpg`} loading="lazy" alt="kid chef" /></Col>
                    <Col sm={12} md={8}>
                        <h3>Welcome to the Power of Produce (PoP) Kids Club! </h3>
                        <p>We are excited to invite children ages 4 to 13 to join our <b>FREE</b> program <b>every Sunday from June to November</b> at the BxPE Market. The PoP Club empowers kids to choose healthy food while having fun at the market.</p>
                        <p className="text-center"><Button href='/popkidsclub' rel="noopener noreferrer" target="_blank" size="lg" variant="success">Join Now!</Button></p>
                        <p>By signing up, you're taking the first step towards a healthier, more vibrant future for our Community. Together, we can cultivate a love for fresh produce and empower our children to make nutritious choices that last a lifetime. Join us in the Power of Produce (PoP) Kids Club today!</p>
                    </Col>
                </Row>
            </Container>

            <Container fluid className="bg-light bg-gradient">
                <Container>
                    <Row className="info-container justify-content-center align-items-center">
                        <Col sm={12} md={4} className="p-0">
                            <img className="img-fluid w-100" src={`${process.env.PUBLIC_URL}/assets/landing/mix-veggies.png`} loading="lazy" alt="cart full of lettuce" />
                        </Col>
                        <Col sm={12} md={8}>
                            <div className="ps-5">
                                <h3 className="title text-dark">What is a CSA?</h3>
                                <h5>Community Supported Agriculture (CSA)</h5>
                                <p>Community Supported Agriculture (CSA) is a production and marketing model whereby consumers buy shares of a farm's harvest in advance. Many CSAs offer on-farm social and educational activities for members, further strengthening their connection to the land and with the farmers who feed them.</p>
                                <p><a className="h6" href="/csa">Know More <em className="text-success">How it Works?</em></a></p>
                                <h3 className="title text-dark">Do you know?</h3>
                                <p>Data collected in 2015 by the U.S. Department of Agriculture indicates that 7,398 farms in the United States sold products directly to consumers through a community supported agriculture (CSA) arrangement. CSA's accounted for $226 million (or 7 percent) of the $3 billion in direct-to-consumer sales by farms.</p>
                                <p><a className="h6" href="https://www.nass.usda.gov/Surveys/Guide_to_NASS_Surveys/Local_Food/" rel="noopener noreferrer" target="_blank">See: <em className="text-success">2015 Local Food Marketing Practices Survey</em></a></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    )
}

export default Landing;