import { Col, Container, ListGroup, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlickr, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import Helmet from "../components/Helmet";

function About() {
    return (
        <>
        <Helmet title={'About'}></Helmet>
            <Container fluid className="d-flex justify-content-center align-items-center about-banner-container banner-container banner-image-container">
                <div><h1 className="display-2">About</h1></div>
            </Container>
            <Container className="mt-5">
                <Row className='justify-content-around'>
                    <Col>
                        <img className="img-fluid w-100 p-0" src={`${process.env.PUBLIC_URL}/assets/leadership/judidesire.jpg`} loading="lazy" alt="Judith 'Judi' Desire" />
                    </Col>
                    <Col sm={12} md={6}>
                        <h2 className="display-4">Judi Desire</h2>
                        <h3>Executive Director / Farmers Market Manager</h3>
                        <p>Judith Desire is a native from Brooklyn, New York who creates eco-friendly green spaces in two Harlem community gardens. In 2018, Judi enrolled in the Snug Harbor Cultural Center &amp; Botanical Garden Master Composting Program where she learned how to build and maintain an outdoor compost system. As a final project she started a DSNY organics collection program and was awarded the 2018 Community-Scale Composting Grant provided by the Citizens Committee for New York City for a 3-bin outdoor wooden compost system built at William B. Washington Garden - Harlem. From 2018-2022, the garden offered 24/7 community drop-off organics collection via the NY Department of Sanitation organics program with collaboration from GrowNYC collected over 1200lbs of food scraps per week and processed 600lbs in the garden’s outdoor compost system. Now, the bin system handles overflow from surrounding community drop-off sites. </p>
                        <p>Judi founded on June 1, 2021, Bronx Park East Community Supported Agriculture (CSA) served as a pilot project that focuses on small-scale implementation of accessing farm fresh food from a local small farm in Bronx Park with an expansion arm in Central Harlem with tailored community outreach and engagement strategy dedicated to nutrition equity.</p>
                        <p>Following 2021's successful first season, the program expanded to Bronx Park East Farmers Market &amp; CSA and Uptown Good Food (Farm Stand) in Central Harlem, New York.</p>
                        <p>Judi is a graduate of the Farm School NYC Citywide Program and the GrowNYC Farmer Assistance Beginning Farmer Program. Judi's focus on urban food access and supporting minority agricultural communities and efforts to establish economically and environmentally sustainable farm businesses in the NYC region.</p>
                        <ListGroup horizontal className='social-links'>
                            <ListGroup.Item className='border-0 bg-transparent px-2'><a href="https://www.linkedin.com/in/jdesire/" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faLinkedinIn} /></a></ListGroup.Item>
                            <ListGroup.Item className='border-0 bg-transparent px-2'><a href="https://flic.kr/s/aHsmVeDRFM" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faFlickr} /></a></ListGroup.Item>
                        </ListGroup>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default About;
