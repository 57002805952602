import { Container } from "react-bootstrap";

function Success() {
    return(
        <Container>
            <h1>Success</h1>
            <p>Thank you for your payment.</p>
        </Container>
    )
    
}

export default Success;