import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faExternalLinkAlt, faNewspaper, faDonate } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faInstagram, faFlickr, faTwitter, faYelp, faLinkedin } from "@fortawesome/free-brands-svg-icons";

import { Container, Row, Col, Button, Card } from 'react-bootstrap';

const divStyle = {
	position: 'absolute',
	left: '-5000px',
};
const divDisplay = {
	display: 'none',
};

function Footer() {
	return (
		<>
			<Container fluid className='mb-5 g-0 text-center'>
				<Row className='g-0 feature-container'>
					<Col className='p-0' xs={12} md={6}>
						<Card className='text-white bg-dark border-0 rounded-0 h-100 feature-nutrition'>
							<Card.Body>
								<Card.Title className='display-4'>Cooking a <br />Nutritious Meal</Card.Title>
								<Card.Text className='lead'>
									Find cooking demonstrations and recipes</Card.Text>
								<p>
									<Button href='/nutrition' size='lg' variant="success">Learn more</Button>
								</p>
							</Card.Body>
						</Card>
					</Col>
					<Col className='p-0' xs={12} md={6}>
						<Card className='text-white bg-dark border-0 rounded-0 h-100 feature-fm-donate'>
							<Card.Body>
								<Card.Title className='display-4'>Community Projects</Card.Title>
								<Card.Text className='lead'>
									Your donation helps bring a food scraps drop-off site at Bronx Park East (BxPE) Farmers Market &amp; CSA and expand the POP Kids Club program in Harlem and The Bronx!
								</Card.Text>
								<p>
									<Button href='/waste-reduction/' size='lg' variant="warning" rel="noopener noreferrer" target="_blank">Donate</Button>
								</p>
							</Card.Body>
						</Card>
					</Col>
					<Col className='p-0' xs={12} md={6}>
						<Card className='text-white bg-dark border-0 rounded-0 h-100 feature-farm-stand'>
							<Card.Body>
								<Card.Title className='display-4'>Uptown Good Food</Card.Title>
								<Card.Text className='lead'>
									A farm stand and farmers market that sells farm produce locally grown in the Tri-State area in Harlem.
								</Card.Text>
								<p>
									<Button href='https://uptowngoodfood.square.site/' size='lg' variant="light" rel="noopener noreferrer" target="_blank">Learn More</Button>
								</p>
							</Card.Body>
						</Card>
					</Col>
					<Col className='p-0' xs={12} md={6}>
						<Card className='text-white bg-dark border-0 rounded-0 h-100 feature-merchandise'>
							<Card.Body>
								<Card.Title className='display-4'>Merchandise</Card.Title>
								<Card.Text className='lead'>
									Purchase kids and adults t-shirts in various sizes and colors
								</Card.Text>
								<p>
									<Button href='/merchandise' size='lg' variant="light">Buy Now</Button>
								</p>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>

			<Container>
				<Row>
					<Col md={3} className="mb-2">
						<ul className="list-unstyled">
							<li className="col-title mb-2"><a href="/contactus">Contact Us</a></li>
							<li>
								<a href="tel:+13473917189">+1-347-391-7189</a><br />
								<a href="mailto:bronx@ubhealthyproject.org?subject = BxParkEastCSA&body = Message">bronx@ubhealthyproject.org</a>
							</li>
						</ul>
						<div className='row align-items-center text-center'>
							<div className='col-4'><a href="/fmnp"><img className="img-fluid" width="50" src={`${process.env.PUBLIC_URL}/assets/logos/nygov-logo.png`} alt="FMNP (Farmers' Market Nutrition Program)" /></a><a href="/fmnp">FMNP</a></div>
							<div className='col-6'><a href="/snap"><img className="img-fluid" width="50" src={`${process.env.PUBLIC_URL}/assets/logos/Supplemental_Nutrition_Assistance_Program_logo.png`} alt="SNAP (Supplemental Nutrition Assistance Program) / EBT (Electronic Benefit Transfer)" /></a><a href="/snap">SNAP/EBT</a></div>
						</div>
						<p className='text-left mt-2'><Button target="_blank" href="https://donate.stripe.com/aEU6rG3USf24eo84gK" size='lg' variant="success">Donate <FontAwesomeIcon icon={faDonate} /></Button></p>
					</Col>
					<Col md={2}>
						<ul className="list-unstyled">
							<li className="col-title"><a href="/csa">About</a></li>
							<li><a href="/about">Leadership</a></li>
							<li className="col-title mt-2">Locations</li>
							<li><a href="/bronx">The Bronx</a></li>
							<li><a href="https://uptowngoodfood.square.site/" rel="noopener noreferrer" target="_blank">Harlem</a></li>
						</ul>
					</Col>
					<Col md={2}>
						<ul className="list-unstyled">
							{/* <li>Watch on Demand</li> */}
							<li className="col-title">Farmers Market</li>
							<li><a href="/fmnp">FMNP</a></li>
							<li><a href="/popkidsclub">POP Kids Club</a></li>
							<li><a href="/snap">SNAP/EBT Benefits</a></li>
							<li><a href="/composting">Composting</a></li>
							<li className="col-title mt-2"><a href="/csa">CSA</a></li>
							<li><a href="/csa">How CSA works?</a></li>
							<li><a href="/membership">Membership</a></li>
							<li><a href="/summer">Summer</a></li>
						</ul>
					</Col>
					<Col md={2}>
						<ul className="list-unstyled">
							<li className="col-title">Get Involved</li>
							<li><a href="https://ubhealthyproject.org/apply">Become a Vendor</a></li>
							<li><a href="https://airtable.com/apptWMYTZ4msRriKR/pagOWx48AjQdPmSJz/form" rel="noopener noreferrer" target="_blank">Vendor Application <FontAwesomeIcon icon={faExternalLinkAlt} size="xs" /></a></li>
							<li>
								<a href="https://forms.gle/eSW4PZpcjYDg8jkFA" rel="noopener noreferrer" target="_blank">Volunteer <FontAwesomeIcon icon={faExternalLinkAlt} size="xs" /></a>
								{/* <a href="mailto:bronx@ubhealthyproject.org?subject = BxParkEastCSA&body = Message" rel="noopener noreferrer" target="_blank">Volunteer <FontAwesomeIcon icon={faExternalLinkAlt} size="xs" /></a> */}
							</li>
							<li><a href="/donate">Donate</a></li>
							{/* <li><a href="https://us1.list-manage.com/survey?u=f0ebc6a0c0ff86aa102416c6a&id=d9ec0201f5&attribution=false" rel="noopener noreferrer" target="_blank">Volunteer <FontAwesomeIcon icon={faExternalLinkAlt} size="xs" /></a></li> */}
							{/* <li>Subscribe</li> */}
							<li className="col-title mt-2">Nutrition</li>
							<li><a href="/nutrition">Recipes</a></li>
						</ul>
					</Col>
					<Col md={3}>
						<ul className="list-unstyled">
							<li className="col-title">Subscribe to e-Newsletter</li>
						</ul>
						<div id="mc_embed_signup" className='w-100'>
							<form action="https://bronxparkeastcsa.us1.list-manage.com/subscribe/post?u=f0ebc6a0c0ff86aa102416c6a&amp;id=2cac704323&amp;f_id=009217e2f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
								<div id="mc_embed_signup_scroll">
									{/* <h2>Subscribe</h2> */}
									<div className="indicates-required text-end"><span className="asterisk">*</span> indicates required</div>
									<div className="mc-field-group">
										<label htmlFor="mce-FNAME" className="form-label">First Name </label>
										<input type="text" value="" readOnly name="FNAME" className="form-control form-control-sm" id="mce-FNAME" />
										<span id="mce-FNAME-HELPERTEXT" className="helper_text"></span>
									</div>
									<div className="mc-field-group">
										<label htmlFor="mce-LNAME" className="form-label">Last Name </label>
										<input type="text" value="" readOnly name="LNAME" className="form-control form-control-sm" id="mce-LNAME" />
										<span id="mce-LNAME-HELPERTEXT" className="helper_text"></span>
									</div>
									<div className="mc-field-group">
										<label htmlFor="mce-EMAIL" className="form-label">Email Address  <span className="asterisk">*</span>
										</label>
										<input type="email" value="" readOnly name="EMAIL" className="required email form-control form-control-sm" id="mce-EMAIL" required />
										<span id="mce-EMAIL-HELPERTEXT" className="helper_text"></span>
									</div>
									<div className="mc-field-group">
										<label htmlFor="mce-MMERGE6" className="form-label">Distribution Locations  <span className="asterisk">*</span>
										</label>
										<select name="MMERGE6" className="required form-select" id="mce-MMERGE6" required>
											<option value="" readOnly></option>
											<option value="Bronx Park East (Pelham Pky)">Bronx Park East (Pelham Pky)</option>
											<option value="Saint Nicholas Miracle Garden (Harlem)">Saint Nicholas Miracle Garden (Harlem)</option>

										</select>
										<span id="mce-MMERGE6-HELPERTEXT" className="helper_text"></span>
									</div>
									<div id="mce-responses" className="clear foot">
										<div className="response" id="mce-error-response" style={divDisplay}></div>
										<div className="response" id="mce-success-response" style={divDisplay}></div>
									</div>
									<div style={divStyle} aria-hidden="true"><input type="text" name="b_f0ebc6a0c0ff86aa102416c6a_2cac704323" tabIndex="-1" value="" readOnly /></div>
									<div className="optionalParent">
										<div className="clear foot">
											<input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button btn btn-warning mt-3" />
											{/* <p className="brandingLogo"><a href="http://eepurl.com/hTdpCH" title="Mailchimp - email marketing made easy and fun"><img src="https://eep.io/mc-cdn-images/template_images/branding_logo_text_dark_dtp.svg" alt='branding_logo_text_dark' /></a></p> */}
										</div>
									</div>
								</div>
							</form>
						</div>
					</Col>
				</Row>
			</Container>

			<hr />
			<Container>
				<Row>
					<Col md={6}>&copy; 2021-{new Date().getFullYear()} Bronx Park East Farmers Market &amp; Community Supported Agriculture (CSA). All rights reserved.<br /><small>BxPE Farmers Market &amp; CSA is the brand name of <a href='https://ubhealthyproject.org/' rel="noopener noreferrer" target="_blank">Uptown &amp; Boogie Healthy Project</a> - 501(c)(3)</small><br /></Col>
					<Col md={6} className="text-right">
						<ul className="list-inline">
							<li className="list-inline-item">Follow us:</li>
							<li className="list-inline-item"><a href="https://www.facebook.com/bxpemarket/" className="text-dark" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faFacebookF} /></a></li>
							<li className="list-inline-item"><a href="https://www.instagram.com/bxpemarket" className="text-dark" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faInstagram} /></a></li>
							<li className="list-inline-item"><a href="https://twitter.com/bxpemarket" className="text-dark" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faTwitter} /></a></li>
							<li className="list-inline-item"><a href="https://flic.kr/s/aHsmVeDRFM" className="text-dark" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faFlickr} /></a></li>
							<li className="list-inline-item"><a href="http://linkedin.com/company/ubhealthyproject" className="text-dark" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faLinkedin} /></a></li>
							<li className="list-inline-item"><a href="https://www.yelp.com/biz/bronx-park-east-csa-new-york" className="text-dark" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faYelp} /></a></li>
							<li className="list-inline-item"><a href="http://eepurl.com/hu12NH" className="text-dark" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faNewspaper} /></a></li>
							<li className="list-inline-item"><a href="https://g.page/ben-abrams-playground" className="text-dark" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faMapMarkerAlt} /></a></li>
						</ul>
					</Col>
				</Row>
			</Container>

		</>
	)
}
export default Footer