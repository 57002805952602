import { Button, Col, Row } from "react-bootstrap";

import { CartContext } from "../CartContext";
import { useContext } from "react";
import { getProductData } from "../productStore";

function CartProduct(props) {
    const cart = useContext(CartContext);
    const ele = props.ele.toString();
    const id = props.id;
    const quantity = props.quantity;
    const productData = getProductData(id);

    return (
        <>
            <div key={ele}>
                <h3>{productData.title}</h3>
                <Row>
                    <Col><p>Qty: {quantity}</p></Col>
                    <Col><p>${(quantity * productData.price).toFixed(2)}</p></Col>
                    <Col><Button size="sm" variant="danger" onClick={() => cart.deleteFromCart(id)}>Remove</Button></Col>
                </Row>
            </div>
            <hr />
        </>
    )
}

export default CartProduct;