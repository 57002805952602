import { Container } from "react-bootstrap"
import Volunteering from "../components/Volunteering"
import Helmet from "../components/Helmet";

function Volunteer() {
    return (
        <>
            <Helmet title={'Volunteering Information'}></Helmet>
            <Container fluid className="d-flex justify-content-center align-items-center default-banner-container banner-container banner-image-container">
                <div><h1 className="display-2">Get Involved</h1></div>
            </Container>
            <Container className="my-5">
                <Volunteering />
            </Container>
        </>
    )
}
export default Volunteer