import { Button, Col, Container, Row } from "react-bootstrap";
import Helmet from "../components/Helmet";

function WasteReduction() {
    return (
        <>
            <Helmet title={'Waste Reduction'}></Helmet>
            <Container fluid className="d-flex justify-content-center align-items-center waste-banner-container banner-container banner-image-container">
                <div><h1 className="display-2">Waste Reduction</h1></div>
            </Container>

            <Container className="my-5 text-center">
                <h2>Reduction of Environmental Degradation</h2>
                <p>We are contributing our quota to reducing air pollution and ensuring a healthier environment through food waste reduction. A food scrap drop-off site has proven to be a far better alternative to a landfill that contributes significantly to environmental pollution.</p>
                <p>In our proposed food scrap drop-off site, organic wastes will be processed and converted into healthy natural fertilizers which will be utilized in local farms and gardens in the Bronx. This way, we will help reduce the toxins, leachate, greenhouse gasses, repugnant odors and seagull and rat infestations caused by landfills.</p>
                <p>Together we can impact The Bronx by implementing comprehensive waste reduction programs and encouraging sustainable behaviors.</p>
            </Container>
            <Container fluid className="bg-light p-md-3 text-center">
                <p className="col-md-6 mx-auto">Your donation supports the food scraps drop-off site and fund compost micro-hauling services at Bronx Park East Farmers Market &amp; CSA in The Bronx!</p>
                <p>
                    <Button href='https://donate.stripe.com/aEU6rG3USf24eo84gK' size='lg' variant="warning" rel="noopener noreferrer" target="_blank">Donate</Button>
                </p>
            </Container>

            <Container className="my-3 text-center">
                <h3>Waste Management Programs</h3>
                <p>Following the successful pilot of a four-week waste reduction program at Bronx Park East Farmers in October 2022, we are thrilled to announce our return this year for a 22-week initiative in partnership with GreenFeen OrganiX and Green Tree Textile Recycling. Our objective remains steadfast in bringing effective Waste Management Programs to The Bronx and fostering sustainable practices.</p>
            </Container>
            <Container>
                <Row className="align-items-center">
                    <Col sm={12} md={12}>
                        <h2 className="text-center">Bronx Food Scraps Drop-Off</h2>
                        <p>We are thrilled to announce our initiative launching from <strong>Sunday, June 2, to November 24, 2024</strong>. We aim to establish a full-season food scrap drop-off site at the Bronx Park East Farmers' Market. We will process the organic waste collected by collaborating with GreenFeen OrganiX (micro-hauler) and Synergi Urban Garden 2.0 (community garden). Additionally, we are excited to offer Kids $2 for each compost drop-off, enhancing our existing program and empowering children to increase their spending power at the farmers' market.</p>
                    </Col>
                    <Col sm={12} md={5} className="mb-3"><img className="img-fluid" src={`${process.env.PUBLIC_URL}/assets/composting/food-scraps.jpg`} alt="Collected Food Scraps" /></Col>
                    <Col sm={12} md={7}>
                        <h4>Reducing Food Waste</h4>
                        <p>With the success of our October 2022 4-week compost drop-off pilot at Bronx Park East Farmers Market, we recognize the need for accessible drop-off sites in The Bronx. Due to limited funding in 2022, we're thrilled to share that Uptown &amp; Boogie Healthy Project will establish a full-season (June to November) food scrap drop-off site at Bronx Park East Farmers Market.</p>
                        <p>
                            <Button href='/food-scraps' size='lg' variant="warning" rel="noopener noreferrer">Learn More</Button>
                        </p>
                    </Col>
                </Row>
                <h2 className="text-center">Bronx Clothing Reuse &amp; Recycling Drop-Off</h2>
                <Row className="align-items-center">
                    <Col sm={12} md={7}>
                        <p>We have partnered with Green Tree Textile Recycling to further our commitment to environmental preservation. Their recycling services support individuals facing disadvantages and those in transitional periods. Green Tree aims to safeguard the environment by repurposing clothing and textiles for reuse by environmentally conscious manufacturers and designers.</p>
                        <p>Through our collaboration with Green Tree Textile Recycling, we endeavor to extend the lifespan of clothing and reduce textile waste. This initiative aligns with our mission to promote sustainable practices and contribute to a circular economy.</p>
                        <p>
                            <Button href='/clothing-drop-off' size='lg' variant="warning" rel="noopener noreferrer">Learn More</Button>
                        </p>
                </Col>
                    <Col sm={12} md={5} className="mb-3"><img className="img-fluid" src={`${process.env.PUBLIC_URL}/assets/composting/GreenTreeTextileRecycling.png`} alt="Green Tree Textile Recycling" /></Col>
                </Row>
            </Container>
        </>
    );

}

export default WasteReduction;
