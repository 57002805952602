import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import './css/fonts.css';
import './css/styles.css';

import { Container } from "react-bootstrap";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavBarComponent from './components/NavBar';

import CartProvider from "./CartContext";

// pages
import Landing from "./pages/Landing";
import About from './pages/About';
import Donate from './pages/Donate';
import FarmersMarket from './pages/FarmersMarket';
import Composting from './pages/Composting';
import WasteReduction from './pages/WasteReduction';
// import Vendors from './pages/Vendors';
import PopKidsClub from './pages/PopKidsClub';
import Nutrition from './pages/Nutrition';
import CSA from './pages/CSA';
import Membership from './pages/Membership';
import SnapBenefits from './pages/SnapBenefits';
import Volunteer from './pages/Volunteer';
import ContactUs from './pages/ContactUs';
import NotFound from './pages/NotFound';
import Merchandise from './pages/Merchandise';

// auth
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import Reset from './pages/auth/Reset';
import Profile from './pages/auth/Profile';

// payment
import Success from "./pages/Success";
import Cancel from "./pages/Cancel";
import Footer from './components/Footer';

// message dialog box
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClothingDropOff from './pages/ClothingDropOff';

function App() {
  return (
    <CartProvider>
      <BrowserRouter>
        <Container fluid className='g-0'>
          <ToastContainer />
          <header>
            <NavBarComponent />
          </header>
          <main>
            <Routes>
              <Route index element={<Landing />} />
              {['about', 'leadership', 'whoweare'].map((path) => (
                <Route key={path} path={path} element={<About />} />
              ))}
              {['donate', 'support', 'donation'].map((path) => (
                <Route key={path} path={path} element={<Donate />} />
              ))}
              <Route path='farmersmarket' element={<FarmersMarket />} />
              {["/vendors/", "/vendor/"].map((path) => (
                <Route key={path} path={path} element={<FarmersMarket />} />
              ))}
              {["/popkidsclub/", "/pop-kids-club/", "/kids/"].map((path) => (
                <Route key={path} path={path} element={<PopKidsClub />} />
              ))}
              {["/waste-reduction/", "/recycle/"].map((path) => (
                <Route key={path} path={path} element={<WasteReduction />} />
              ))}
              {["/composting/", "/food-scraps/", "/foodscraps/", "/compost-drop-off/"].map((path) => (
                <Route key={path} path={path} element={<Composting />} />
              ))}
              {["/clothing-dropoff/", "/clothing-drop-off/", "/clothing-recycle"].map((path) => (
                <Route key={path} path={path} element={<ClothingDropOff />} />
              ))}
              {["/nutrition/", "/recipes/", "nutrition-programs"].map((path) => (
                <Route key={path} path={path} element={<Nutrition />} />
              ))}
              {["/csa/", "/csa/bronx/", "/bronx/", "/learn/", "/summer/", "enrollment"].map((path) => (
                <Route key={path} path={path} element={<CSA />} />
              ))}
              {["/membership/", "/members/"].map((path) => (
                <Route key={path} path={path} element={<Membership />} />
              ))}
              {["/snap/", "/snap_benefits/", "/snapbenefits/"].map((path) => (
                <Route key={path} path={path} element={<SnapBenefits />} />
              ))}
              {["/volunteer/", "/volunteers/"].map((path) => (
                <Route key={path} path={path} element={<Volunteer />} />
              ))}
              {["/contactus/", "/contact/"].map((path) => (
                <Route key={path} path={path} element={<ContactUs />} />
              ))}
              <Route path='/merchandise/' element={<Merchandise />} />


              <Route path='login' element={<Login />} />
              <Route path='register' element={<Register />} />
              <Route path='profile' element={<Profile />} />
              <Route path='reset' element={<Reset />} />
              <Route path='success' element={<Success />} />
              <Route path='cancel' element={<Cancel />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </main>
          <footer className="my-5"><Footer /></footer>
        </Container>
      </BrowserRouter>

    </CartProvider>
  );
}

export default App;