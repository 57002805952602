import { useState } from "react";
import { Button, Container, Form } from "react-bootstrap";

import { toast } from 'react-toastify';

import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import { auth, db } from "../../firebase/config";

import { useNavigate } from "react-router-dom";

function Register() {
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [cPassword, setCPassword] = useState('');
    const registerUser = (e) => {
        e.preventDefault();
        setLoading(true);

        if (password !== cPassword) {
            toast.error("Passwords do not match.", {
                position: toast.POSITION.TOP_CENTER,
                theme: "dark"
            });
        }

        // Create a password-based account
        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                // console.log("user", user);
                // ...
                setLoading(false);
                toast.success("Registration Successful", {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "dark",
                    autoClose: 2000
                });

                updateProfile(auth.currentUser, {
                    displayName: name
                });

                const docData = {
                    uid: user.uid,
                    email: user.email,
                    name: name,
                    timestamp: serverTimestamp()
                };
                const docRef = doc(db, "users", user.uid);
                setDoc(docRef, docData, { merge: true });

                navigate("/login/");
            })
            .catch((error) => {
                // const errorCode = error.code;
                setLoading(false);
                // ..
                const errorMessage = error.message;
                if (error.code === 'auth/email-already-in-use') {
                    toast.error("Email Already in Use", {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored"
                    });
                } else if (error.code === 'auth/weak-password') {
                    toast.error("Weak-Password", {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored"
                    });
                } else {
                    toast.error(errorMessage, {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored"
                    });
                }
            });

    };

    const formClassName = `ui form ${isLoading ? 'loading' : ''}`;

    return (
        <>
            <Container className="text-center my-5">
                <h1>Register</h1>
            </Container>
            <Container className="col-md-6 mx-auto p-5 shadow">
                <Form className={formClassName} onSubmit={registerUser}>
                    <Form.Group className="mb-3" controlId="formDisplayName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control size="lg" type="text" placeholder="First and Last name" required value={name} onChange={(e) => setName(e.target.value)} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formGridEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control size="lg" type="email" placeholder="Email Address" required value={email} onChange={(e) => setEmail(e.target.value)} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formGridPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control size="lg" type="password" placeholder="Password" required value={password} onChange={(e) => setPassword(e.target.value)} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formGridConfirmPassword">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control size="lg" type="password" placeholder="Confirm Password" required value={cPassword} onChange={(e) => setCPassword(e.target.value)} />
                    </Form.Group>
                    <div className="d-grid gap-2">
                        <Button variant="success" type="submit">
                            Register
                        </Button>
                    </div>
                </Form>
            </Container>
        </>
    );

}

export default Register;