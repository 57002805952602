import { Button, Col, Container, Row } from "react-bootstrap";
import Helmet from "../components/Helmet";

function ClothingDropOff() {
    return (
        <>
            <Helmet title={'Clothing Drop-Off'}></Helmet>
            <Container fluid className="d-flex justify-content-center align-items-center waste-banner-container banner-container banner-image-container">
                <div><h1 className="display-2">Clothing Drop-Off</h1></div>
            </Container>

            <Container fluid className="bg-light p-md-3 text-center">
                <p className="col-md-6 mx-auto">Your donation supports the food scraps drop-off site and fund compost micro-hauling services at Bronx Park East Farmers Market &amp; CSA in The Bronx!</p>
                <p>
                    <Button href='https://donate.stripe.com/aEU6rG3USf24eo84gK' size='lg' variant="warning" rel="noopener noreferrer" target="_blank">Donate</Button>
                </p>
            </Container>

            <Container>
                <Row className="align-items-center">
                <Col sm={12} md={5} className="mb-3"><img className="img-fluid" src={`${process.env.PUBLIC_URL}/assets/composting/clothing-drop-off.jpg`} alt="Green Tree Textile Recycling" /></Col>
                <Col sm={12} md={6}>
                        <h2>Clothing Recycling</h2>
                        <p>We collaborated with Green Tree Textile Recycling, recycling services support people, who are disadvantaged and those in transition. Green Tree strives to preserve the environment by repurposing clothing and textiles for re-use by environmentally minded manufacturers and designers.</p>
                        <p>
                            <Button href='https://www.greentreetextiles.org/tax-receipt/' size='lg' variant="warning" rel="noopener noreferrer">Obtain Tax-Receipt for Donated Clothing /Textiles</Button>
                        </p>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="align-items-center">
                    <Col sm={12} md={7}>
                        <h2>Bronx Clothing Reuse &amp; Recycling Drop-Off</h2>
                        <p>We have partnered with Green Tree Textile Recycling to further our commitment to environmental preservation. Their recycling services support individuals facing disadvantages and those in transitional periods. Green Tree aims to safeguard the environment by repurposing clothing and textiles for reuse by environmentally conscious manufacturers and designers.</p>
                        <p>Through our collaboration with Green Tree Textile Recycling, we endeavor to extend the lifespan of clothing and reduce textile waste. This initiative aligns with our mission to promote sustainable practices and contribute to a circular economy.</p>
                        <p>
                            <Button href='/clothing-drop-off' size='lg' variant="warning" rel="noopener noreferrer">Learn More</Button>
                        </p>
                    </Col>
                    <Col sm={12} md={5} className="mb-3"><img className="img-fluid" src={`${process.env.PUBLIC_URL}/assets/composting/GreenTreeTextileRecycling.png`} alt="Green Tree Textile Recycling" /></Col>
                </Row>

            </Container>
        </>
    );

}

export default ClothingDropOff;
