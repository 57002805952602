import { Col, Container, Row, Button } from "react-bootstrap"

function Volunteering() {
    return (
        <>
            <Container fluid id="volunteering">
                <div className="text-center">
                    <h2 className="display-4">Volunteering</h2>
                    <p>Our farm programs couldn't function without your hard work and dedication in bring farm fresh food to the community!</p>
                </div>
                <Row>
                    <Col xs={12} md={6}>
                        <h3>Do I have to volunteer to be a part of the CSA?</h3>
                        <p>Yes and No, we are a volunteer-run program and rely on each member to assist.</p>
                        <p>If you are splitting a share with another household or individual(s), you can split your volunteer shifts. Each share is responsible for two volunteer shits, <strong>no matter</strong> how many people are splitting the share.</p>
                    </Col>
                    <Col xs={12} md={6}>
                        <h3>How do I sign-up for my volunteer shift?</h3>
                        <p>We are an all-volunteer run organization. By joining the CSA, we ask to sign-up for at least 2 volunteer shifts per season. Children are always welcome to any shift!</p>
                        <p>Please contact us directly to learn more at <a href="tel:+13473917189">+1-347-391-7189</a> or <a href="mailto:bronx@ubhealthyproject.org?subject = BxParkEastCSA&body = Message">bronx@ubhealthyproject.org</a></p>
                    </Col>
                    <Col xs={12} md={6}>
                        <h3>What happens during a volunteer shift?</h3>
                        <p>The primary jobs are to manage member check-in, traffic flow and share distribution. A site manager will assign you a task and answer questions.</p>
                        <p>Duties are designed to help the distribution site run smoothly.</p>
                        <p>It is essential to arrive to your shift on time. If you are delayed, please alert us by call/text <a href="tel:+13473917189">+1-347-391-7189</a></p>
                    </Col>
                    <Col xs={12} md={6}>
                        <h3>What are the volunteer shifts?</h3>
                        <p>The opening shift volunteers may be required to do <strong>heavy lifting</strong> while unloading boxes from the truck. If this will be difficult for you, we recommend selecting the closing shifts.</p>
                    </Col>
                    <Col xs={12} md={6}>
                        <h3>What are the volunteer shift times?</h3>
                        <p>The <strong>opening shift</strong> is 60-30 minutes before distribution time. The <strong>closing shifts</strong> is 30 minutes before the end of distribution time.</p>
                        <p className='text-center'><Button href="https://forms.gle/ZvtQ2phm8evhFyKw5" size="lg" variant="warning">Sign up for a CSA Volunteering Shifts</Button>{''} </p>
                    </Col>
                    <Col xs={12} md={6}>
                        <h3>What if I'm unable to volunteer?</h3>
                        <p>We understand it's difficult to schedule time and commitments are too inflexible.</p>
                        <p>If you are unable to assist us in-person, there are other ways to volunteer your time.</p>
                        <p>Please contact us directly at <a href="mailto:bronx@ubhealthyproject.org?subject = BxParkEastCSA&body = Message">bronx@ubhealthyproject.org</a> and we will provide you with alternative tasks.</p>
                    </Col>
                </Row>
            </Container>

        </>
    )
}
export default Volunteering