import { Button, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faNewspaper } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faFlickr, faInstagram, faLinkedin, faTwitter, faYelp } from "@fortawesome/free-brands-svg-icons";
import Helmet from "../components/Helmet";

function ContactUs() {
    return (
        <>
            <Helmet title={'Contact Us'}></Helmet>
            <Container fluid className="d-flex justify-content-center align-items-center default-banner-container banner-container banner-image-container">
                <div><h1 className="display-2">Contact Us</h1></div>
            </Container>
            <Container className="my-5">
                <Row>
                    <Col xs={12} md={6}>
                        <ul className="list-unstyled">
                            <li>
                                Bronx Park near <em>Brady Playground</em>
                            </li>
                            <li><address>
                            2045 Bronx Pk E, Bronx, NY 10462</address>
                                <a href="https://g.page/brady-playground?share" rel="noopener noreferrer" target="_blank">Bronx Park East &amp; Brady Avenue <FontAwesomeIcon icon={faMapMarkerAlt} /></a>
                            </li>
                            <li>
                                <a href="tel:+13473917189">+1-347-391-7189</a><br />
                                <a href="mailto:bronx@ubhealthyproject.org?subject=BxParkEastCSA&body = Message">bronx@ubhealthyproject.org</a>
                            </li>
                        </ul>
                        <ul className="list-inline">
                            <li className="list-inline-item"><Button href="https://calendar.app.google/1Tid4rtym5LpUDiK7" size="lg" variant="warning">Schedule an Appointment</Button></li>
                            <li className="list-inline-item"><Button href="https://forms.gle/CbKM4sfSCaTc6rNdA" size="lg" variant="warning">Become a Vendor</Button></li>
                        </ul>
                        <ul className="list-inline">
                            <li className="list-inline-item">Follow us:</li>
                            <li className="list-inline-item"><a href="https://www.facebook.com/bxpemarket/" className="text-dark" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faFacebookF} /></a></li>
                            <li className="list-inline-item"><a href="https://www.instagram.com/bxpemarket" className="text-dark" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faInstagram} /></a></li>
                            <li className="list-inline-item"><a href="https://twitter.com/bxpemarket" className="text-dark" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faTwitter} /></a></li>
                            <li className="list-inline-item"><a href="https://flic.kr/s/aHsmVeDRFM" className="text-dark" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faFlickr} /></a></li>
                            <li className="list-inline-item"><a href="http://linkedin.com/company/bxpemarket" className="text-dark" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faLinkedin} /></a></li>
                            <li className="list-inline-item"><a href="https://www.yelp.com/biz/bronx-park-east-csa-new-york" className="text-dark" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faYelp} /></a></li>
                            <li className="list-inline-item"><a href="http://eepurl.com/hu12NH" className="text-dark" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faNewspaper} /></a></li>
                            <li className="list-inline-item"><a href="https://g.page/ben-abrams-playground" className="text-dark" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faMapMarkerAlt} /></a></li>
                        </ul>
                    </Col>
                    <Col xs={12} md={6}>
                        <div className="google-map-code">
                            <iframe title="Google Map of Distribution Location" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d909.7650840306499!2d-73.86888489972353!3d40.85114852021984!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2f51ce749673f%3A0xeec6069bf7d558af!2sBronx%20Park%20East%20Farmer%20Market!5e0!3m2!1sen!2sus!4v1706396053016!5m2!1sen!2sus" width="100%" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy"></iframe>
                        </div>
                    </Col>
                </Row>

            </Container>
        </>
    );

}

export default ContactUs;