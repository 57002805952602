import { Badge, Button, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt, faKitchenSet, faPeopleCarryBox, faTractor } from "@fortawesome/free-solid-svg-icons";
import ReusableBags from "../components/ReusableBags";
import Helmet from "../components/Helmet";


function CSA() {

    return (
        <>
            <Helmet title={'Community Community Supported Agriculture (CSA)'}></Helmet>
            <Container fluid className="d-flex justify-content-center align-items-center default-banner-container banner-container banner-image-container">
                <div><h1 className="display-2">Community Supported Agriculture</h1></div>
            </Container>
            <Container className="my-5 text-center">
                <p className="h3">Start eating seasonally produce and supporting your local food system.</p>
                <p>The farmer provides various types of seasonal farm fresh produce locally grown in New York State for <strong>weekly, monthly and/or full season</strong>.</p>
            </Container>
            <Container>
                <Row className="bg-light py-5 px-3">
                    <Col xs={12} md={6}>
                        <h2>What is a CSA?</h2>
                        <p>
                            Community Supported Agriculture (CSA) is a production and marketing model whereby consumers buy shares of a farm's harvest in advance. <br />Many CSAs offer on-farm social and educational activities for members, further strengthening their connection to the land and with the farmers who feed them.
                        </p>
                        <p>CSA can be a great way to support local farmers and eat healthier, fresher food!</p>

                        <h2>2024 CSA Season</h2>
                        <ul className="list-unstyled">
                            <li><strong>Season:</strong> Sundays, June - November / 9am - 4pm</li>
                            <li><strong>Location:</strong> 2045 Bronx Park East, The Bronx, NY 10462</li>
                        </ul>
                    </Col>
                    <Col xs={12} md={6}>
                        <h2>Enrollment</h2>
                        <p>Each week we harvest a balance of our organic salad greens, root vegetables, cooking greens, seasonal vegetables, herbs and more. </p>
                        <p>We collect funds before <strong>May</strong> to cover farmer's incurred cost. <br />A full season payment will support farmers with mobilization cost to produce member's shares.</p>
                        <p className="text-center m-0">
                            <Button href='https://ubhealthyproject.org/csa/' size="lg" variant="warning">Join Today!</Button>
                        </p>
                    </Col>
                </Row>
            </Container>

            <Container fluid className="d-flex m-0 flex-column jumbotron justify-content-center csa-info-jumbotron text-center mb-5">
                <Container className="mb-5">
                    <h3 className="hero-hed text-dark">How it works?</h3>
                    <p className="col-md-9 mx-auto">A farmer offers a certain number of "shares" to the public. Typically the share consists of a box of vegetables, but other farm products such as fruit, eggs, dairy and even meat may be included. Interested consumers purchase a share (or subscription) and in return receive a delivery of seasonal produce each week, or every other week, throughout the farming season. Deliveries are made to designated locations at consistent times.</p>
                </Container>

                <Row className="info-wrapper d-flex justify-content-around">
                    <Col sm={12} md={3} className="col-item shadow-lg mb-sm-5">
                        <div className="info-item">
                            <div className="item">
                                <div className="text-block">
                                    <Badge className="rounded-circle p-5 mx-auto" bg="success"><FontAwesomeIcon icon={faTractor} size="5x" /></Badge>
                                    <h3 className="my-4">The Farmer</h3>
                                    <p className="text-dark mx-3 lh-sm">Members buy shares growing season from our local farmer who grows and packages the produce.</p>
                                    <p><strong>Farmer:</strong> Grandpa Farm</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} md={3} className="col-item shadow-lg mb-sm-5">
                        <div className="info-item">
                            <div className="item">
                                <div className="text-block">
                                    <Badge className="rounded-circle p-5 mx-auto" bg="success"><FontAwesomeIcon icon={faPeopleCarryBox} size="5x" /></Badge>
                                    <h3 className="my-4">Members Pickup Produce</h3>
                                    <p className="text-dark mx-3 lh-sm">The farmer brings produce to the below distribution locations for members in-person pickup.</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} md={3} className="col-item shadow-lg mb-sm-5">
                        <div className="info-item">
                            <div className="item">
                                <div className="text-block">
                                    <Badge className="rounded-circle p-5 mx-auto" bg="success"><FontAwesomeIcon icon={faKitchenSet} size="5x" /></Badge>
                                    <h3 className="my-4">Farm-to-Fork</h3>
                                    <p className="text-dark mx-3 lh-sm">Make delicious meals in real-time with locally group fresh ingredients!</p>
                                    <p><Button href="/nutrition" variant="warning">Cooking Recipes</Button></p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container id="enrollment" className="my-5">
                <Container className="my-3 text-center">
                    <h2 className="display-3">Enrollment</h2>
                </Container>
                <Container>
                    <Row>
                        <Col xs={12} md={6}>
                            <p className="lead">We are collecting spring funds spring and summer funds to cover farmer's incurred cost for the 2024 season.</p>

                            <h2>Pelham Parkway, Bronx</h2>
                            <Row className="align-items-start">
                                <Col xs={12} md={6}>
                                    <strong>Address: </strong><br />
                                    Bronx Park near Brady Playground
                                    <address>2045 Bronx Park East, The Bronx, NY 10462</address>
                                    <em><a href="https://g.page/brady-playground" rel="noopener noreferrer" target="_blank">Google Map <FontAwesomeIcon icon={faExternalLinkAlt} /></a></em>
                                </Col>
                                <Col xs={12} md={6}>
                                    <strong>Distribution Day: </strong><br />
                                    <ul className="list-unstyled">
                                        <li>Sundays / 9am - 4pm</li>
                                        <li>June - November (22 weeks)</li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={6} className="text-center">
                            <img className="img-fluid" src={`${process.env.PUBLIC_URL}/assets/csa/csa-weekly-produce.png`} alt="csa weekly produce" />
                        </Col>
                    </Row>
                </Container>
            </Container>

            <ReusableBags />
        </>
    );

}

export default CSA;
