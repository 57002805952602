import { Container } from 'react-bootstrap';
import Helmet from '../components/Helmet';

function Merchandise() {
    return (
        <>
            <Helmet title={'Merchandise'}></Helmet>
            <Container fluid className="d-flex justify-content-center align-items-center default-banner-container banner-container banner-image-container">
                <div><h1 className="display-2">Merchandise</h1></div>
            </Container>
            <Container className="text-center p-5">
                <h2>Coming Soon</h2>
            </Container>
        </>
    )
}
export default Merchandise;