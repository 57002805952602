import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faShoppingBag } from "@fortawesome/free-solid-svg-icons";

import { Button, Col, Container, Row } from "react-bootstrap";

function Benefits() {
    return (
        <Container className="mt-5">
            <h2 className="display-3 text-center mb-5">We Accept</h2>
            <Row className="row-cols-md-2 row-cols-lg-10 g-2 g-lg-3">
                <Col sm={12}>
                    <h3 className="display-6">SNAP/EBT</h3>
                    <p>Are you one of the 1.6 million New Yorkers who utilize SNAP (Supplemental Nutrition Assistance Program), formerly known as the Food Stamp Program? Are you looking to use SNAP EBT benefits towards farm fresh food at BxPE CSA?</p>
                    <p><Button href="/snap" size="lg" variant="warning">Learn more <FontAwesomeIcon icon={faInfoCircle} /></Button>{''} <Button href="https://forms.gle/t9KPz3UwcceegyAaA" size="lg" variant="outline-success">Pre-Order (SNAP/EBT) <FontAwesomeIcon icon={faShoppingBag} /></Button>{''}</p>
                </Col>
                <Col sm={12}>
                    <h3 className="display-6">Farmers' Market Nutrition Program</h3>
                    <p className="lead">The FMNP was established by Congress in 1992, to provide fresh, unprepared, locally grown fruits and vegetables to Women, Infants and Children Program (WIC) participants, and to expand the awareness, use of, and sales at farmers' markets. The farmers market welcomes also seniors through the Senior Farmers' Market Nutrition Program (SFMNP) vouchers.</p>
                    <p><Button href="https://agriculture.ny.gov/consumer-benefits-farmers-markets" size="lg" variant="warning" rel="noopener noreferrer" target="_blank">Learn more <FontAwesomeIcon icon={faInfoCircle} /></Button>{''} <Button href="https://www.nyc.gov/site/dfta/about/farmers_market_nutrition_program.page" size="lg" variant="outline-success">The Department for the Aging's Farmers' Market Nutrition Program <FontAwesomeIcon icon={faInfoCircle} /></Button>{''}</p>
                </Col>
                <Col sm={12}>
                    <h3 className="display-6">Health Bucks</h3>
                    <p className="lead">Health Bucks are $2 coupons that can be used to purchase fresh fruits and vegetables at all NYC farmers markets.</p>
                    <p><Button href="https://www1.nyc.gov/site/doh/health/health-topics/health-bucks.page" size="lg" variant="warning" rel="noopener noreferrer" target="_blank">Learn more <FontAwesomeIcon icon={faInfoCircle} /></Button>{''}</p>
                </Col>
                <Col sm={12}>
                    <h3 className="display-6">FreshConnect</h3>
                    <p className="lead">In collaboration with the New York State Department of Agriculture and Markets, Veterans Benefits Advisors with the New York State Division of Veterans' Services are offering FreshConnect checks to Active Duty members, veterans and their immediate family members, as well as un-remarried surviving spouses of veterans.</p>
                    <p><Button href="https://veterans.ny.gov/content/freshconnect-checks-veterans-and-families" size="lg" variant="warning" rel="noopener noreferrer" target="_blank">Learn more <FontAwesomeIcon icon={faInfoCircle} /></Button>{''}</p>
                </Col>
                <Col sm={12}>
                    <h3 className="display-6">Over-The-Counter (OTC)</h3>
                    <p className="lead">OTC Network Healthy Foods Card allows health plan members to use benefit dollars to purchase eligible food products such as fruits, vegetables, eggs, milk and more.</p>
                    <p><Button href="https://www.health.ny.gov/professionals/patients/medicines/over_the_counter/" size="lg" variant="warning" rel="noopener noreferrer" target="_blank">Learn more <FontAwesomeIcon icon={faInfoCircle} /></Button>{''}</p>
                </Col>
            </Row>
        </Container>
    )
}
export default Benefits