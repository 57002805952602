import { Button, Container, ListGroup, Modal, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useState, useContext, useEffect } from "react";
// useState allows us to have local variables 
import logo from "../img/logo-bxpe.png";

import { CartContext } from "../CartContext";
import CartProduct from "./CartProduct";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket, faDonate, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
// import { faDonate, faHandshake, faMapMarkerAlt, faSeedling, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
// import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";

import { toast } from 'react-toastify';

import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../firebase/config";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SET_ACTIVE_USER, REMOVE_ACTIVE_USER } from "../redux/features/authSlice";
import ShowOnLogin, { ShowOnLogout } from "./hiddenLinks/hiddenLinks";

function NavBarComponent() {
    const [displayName, setDisplayName] = useState('');

    const navigate = useNavigate();
    // Set an active user
    const dispatch = useDispatch();
    const cart = useContext(CartContext);

    const [show, setShow] = useState(false);
    // false to hide modal when page loads
    const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    // Monitor current sign in user; runs on page loads
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                // const uid = user.uid;
                // ...
                user.displayName === null ? setDisplayName("Welcome Back!") : setDisplayName(user.displayName)

                // Dispatch active user and save user's information to redux
                dispatch(SET_ACTIVE_USER({
                    email: user.email,
                    userName: user.displayName,
                    userID: user.uid,
                }))
            } else {
                // User is signed out
                // ...
                setDisplayName('');
                dispatch(REMOVE_ACTIVE_USER())
            }
        });
    }, [dispatch]);

    const logoutUser = (e) => {
        e.preventDefault();
        signOut(auth).then(() => {
            // Sign-out successful.
            toast.success("Logout Successful", {
                position: toast.POSITION.TOP_CENTER,
                theme: "dark",
                autoClose: 1000
            });
            navigate("/login");
        }).catch((error) => {
            const errorMessage = error.message;
            // An error happened.
            toast.error(errorMessage, {
                position: toast.POSITION.TOP_CENTER,
                theme: "colored"
            });
        });

    };

    const checkout = async () => {
        await fetch('https://ubhp-store.herokuapp.com/checkout', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "Cache-Control": null,
                "X-Requested-With": null,
            },
            body: JSON.stringify({ items: cart.items })
        }).then((response) => {
            return response.json();
        }).then((response) => {
            if (response.url) {
                window.location.assign(response.url);
            }
        })
    }

    const productsCount = cart.items.reduce((sum, product) => sum + product.quantity, 0);


    return (
        <>
            <Navbar fixed="top" collapseOnSelect expand="lg" variant="light">
                <Container>
                    <Navbar.Brand className="logo" href="/"><img src={logo} alt="logo" width={60} /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <NavDropdown title="About" id="collasible-nav-dropdown">
                                <NavDropdown.Item href="/about">Who are We</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Farmers Market" id="collasible-nav-dropdown">
                                <NavDropdown.Item href="/farmersmarket">About</NavDropdown.Item>
                                <NavDropdown.Item href="/popkidsclub">POP Kids Club</NavDropdown.Item>
                                <NavDropdown.Item href="https://ubhealthyproject.org/apply" target="_blank">Vendors' Information</NavDropdown.Item>
                                <NavDropdown.Item href="/waste-reduction">Waste Reduction</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="/snap">SNAP/EBT Benefits</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="CSA" id="collasible-nav-dropdown">
                                <NavDropdown.Item href="/csa">Enrollment</NavDropdown.Item>
                                <NavDropdown.Item href="/membership">Membership FAQs</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Get Involved" id="collasible-nav-dropdown">
                                <NavDropdown.Item href="https://ubhealthyproject.org/hiring" target="_blank">Hiring</NavDropdown.Item>
                                <NavDropdown.Item href="/volunteer">Volunteer</NavDropdown.Item>
                                <NavDropdown.Item href="/donate">Donate</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="/recipes">Recipes</Nav.Link>
                            <Nav.Link href="/contactus">Contact</Nav.Link>
                        </Nav>

                        <Nav className="me-auto fw-bolder">
                            <ShowOnLogout>
                                {/* <Nav.Link href="/login">Login</Nav.Link>
                                <Nav.Link href="/register">Register</Nav.Link> */}
                            </ShowOnLogout>
                            <ShowOnLogin>
                                <NavDropdown title={displayName} id="collasible-nav-dropdown">
                                    {/* <NavDropdown.Item href="/profile">Profile <FontAwesomeIcon icon={faUserCircle} /></NavDropdown.Item> */}
                                    <NavDropdown.Item href="/" onClick={logoutUser}>Logout <FontAwesomeIcon icon={faArrowRightFromBracket} /></NavDropdown.Item>
                                </NavDropdown>
                            </ShowOnLogin>
                        </Nav>

                        {/* <Nav>
                            <ListGroup className="cart-items" horizontal onClick={handleShow}>
                                <ListGroup.Item as="li" className="border-0 d-flex justify-content-between align-items-start bg-transparent">
                                    <div className="me-auto">
                                        <FontAwesomeIcon size="2x" icon={faShoppingCart} />
                                    </div>
                                    <sup><h5>
                                        <Badge className='badge rounded-pill bg-danger text-white'>{productsCount}</Badge>{' '}</h5></sup>
                                </ListGroup.Item>
                            </ListGroup>
                        </Nav> */}

                        <Nav>
                            <ListGroup horizontal className='social-links'>
                                {/* <ListGroup.Item className='border-0 bg-transparent px-2'><a href="https://www.instagram.com/bxpemarket" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faInstagram} /></a></ListGroup.Item>
                                <ListGroup.Item className='border-0 bg-transparent px-2'><a href="https://www.facebook.com/bxpemarket/" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faFacebookF} /></a></ListGroup.Item>
                                <ListGroup.Item className='border-0 bg-transparent px-2'><a href="https://airtable.com/apptWMYTZ4msRriKR/pagOWx48AjQdPmSJz/form" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faHandshake} /></a></ListGroup.Item> */}
                                <ListGroup.Item className='border-0 bg-transparent px-2'><a href="https://donate.stripe.com/aEU6rG3USf24eo84gK" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faDonate} /></a></ListGroup.Item>
                                <ListGroup.Item className='border-0 bg-transparent px-2'><a href="https://g.page/brady-playground?share" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faMapMarkerAlt} /></a></ListGroup.Item>
                            </ListGroup>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>


            {/* <Navbar bg="light" expand="lg">
                <Container>
                    <Navbar.Brand href="#home">UBHP Store</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                        <Nav className="me-auto">
                            <Nav.Link href="#">Home</Nav.Link>
                            <NavDropdown title="CSA" id="basic-nav-dropdown">
                                <NavDropdown.Item href="#">Harlem</NavDropdown.Item>
                                <NavDropdown.Item href="#">
                                    Bronx
                                </NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="#">Merchandise</Nav.Link>
                        </Nav>
                        <Navbar.Text>
                            <Button onClick={handleShow}>Cart ({productsCount}) items</Button>
                        </Navbar.Text>
                    </Navbar.Collapse>
                </Container>
            </Navbar> */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Shopping Cart</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {productsCount > 0 ?
                        <>
                            {cart.items.map((currentProduct, idx) => (
                                <div key={idx}>
                                    <CartProduct ele={idx} id={currentProduct.id} quantity={currentProduct.quantity}></CartProduct>
                                </div>
                            ))}
                            <h1>Total: ${cart.getTotalCost().toFixed(2)}</h1>
                            {auth.currentUser === null
                                ?
                                <a className="btn btn-warning" href="/login" role="button">Proceed to Checkout</a>
                                :
                                <Button variant="success" onClick={checkout}>
                                    Purchase Items!
                                </Button>
                            }

                        </>
                        :
                        <h1>There are no items in your cart!</h1>
                    }
                </Modal.Body>
            </Modal>
        </>
    );
}
export default NavBarComponent;