import { sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { auth } from "../../firebase/config";

function Reset() {
    const [isLoading, setLoading] = useState(false);
    const [email, setEmail] = useState('');

    const resetPassword = (e) => {
        e.preventDefault();
        setLoading(true);
        sendPasswordResetEmail(auth, email)
            .then(() => {
                setLoading(false);
                // ..
                toast.success("Password reset email sent!", {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "dark",
                    autoClose: 1000
                });
            })
            .catch((error) => {
                // const errorCode = error.code;
                const errorMessage = error.message;
                setLoading(false);
                // ..
                if (error.code === 'auth/user-not-found') {
                    toast.error("Unable to locate account", {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored"
                    });
                } else {
                    toast.error(errorMessage, {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored"
                    });
                }
            });

    };
    const formClassName = `ui form ${isLoading ? 'loading' : ''}`;

    return (
        <>
            <Container className="text-center my-5">
                <h1 className="display-3">Forgot your password?.</h1>
                <p className="lead">Please enter your email address, a link to reset your password will be sent to your email address.</p>
            </Container>
            <Container className="col-md-6 mx-auto p-5 shadow">
                <Form className={formClassName} onSubmit={resetPassword}>
                    <Form.Group className="mb-3" controlId="formGroupEmail">
                        <Form.Label>Email</Form.Label>
                        {/* <Form.Control size="lg" type="email" placeholder="Enter email" /> */}
                        <Form.Control size="lg" type="email" placeholder="Enter email" required value={email} onChange={(e) => setEmail(e.target.value)} />
                    </Form.Group>
                    <div className="d-grid gap-2">
                        <Button variant="success" size="lg" type="submit">
                            Reset Password
                        </Button>
                    </div>
                </Form>
                <p className="d-flex justify-content-between">
                    <Link to="/login">Login</Link>
                    <Link to="/register">Register</Link>
                </p>
            </Container>
        </>
    );

}

export default Reset;