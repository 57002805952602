// Config the redux store
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authReducer from "./features/authSlice";
import cartSlice from "./features/cartSlice";

const rootReducer = combineReducers({
 auth: authReducer,
 cart: cartSlice,
});

const store = configureStore({
    reducer: rootReducer,
});

export default store;