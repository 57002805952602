import { createContext, useState, useEffect } from "react";
import { getProductData } from "./productStore";

export const CartContext = createContext({
    
    items: [],
    getProductQuantity: () => { },
    addOneToCart: () => { },
    removeOneFromCart: () => { },
    deleteFromCart: () => { },
    getTotalCost: () => { }
});




export function CartProvider({ children }) {
    const [cartProducts, setCartProducts] = useState([]);
    
     //retrieves cart data from local storage 
     useEffect(()=>{
        const data = window.localStorage.getItem('BxPE_Cart');
        if (data != null) setCartProducts(JSON.parse(data))
        // console.log("cart retrived")
       },[])


    useEffect(()=>{
        window.localStorage.setItem('BxPE_Cart', JSON.stringify(cartProducts))
    },[cartProducts])

    /* 
        The cart will store the array data
        [{ id: 1, quality: 2}]
    */

    // function to get product quantity 
    function getProductQuantity(id) {
        const quantity = cartProducts.find(product => product.id === id)?.quantity;
        /*
        - quantity to find the product with a certain id
        - ? mark before the dot means if the .find gets an undefined object with no product id the ?mark will not get the products quantity. The ? is acts as a Ternary Operator in javascript
        */
        if (quantity === undefined) {
            return 0;
        }
        return quantity;
    }

    function addOneToCart(id) {
        const quantity = getProductQuantity(id);
        // why do we need this? To see if there's zero or one element
        if (quantity === 0) {
            setCartProducts(
                [
                    ...cartProducts,
                    {
                        id: id,
                        quantity: 1
                    }

                ]
            )
        } else {
            // product is in cart
            // [{ id: 1, quality: 2}, { id: 2, quality: 1 + 1}]
            setCartProducts(
                cartProducts.map(
                    product =>
                        product.id === id // if condition
                            ? { ...product, quantity: product.quantity + 1 } // if statement is true
                            : product // if statement is false
                )
            )
        }
    }

    function removeOneFromCart(id) {
        const quantity = getProductQuantity(id);
        if (quantity === 1) {
            deleteFromCart(id);
        } else {
            setCartProducts(
                cartProducts.map(
                    product =>
                        product.id === id // if condition
                            ? { ...product, quantity: product.quantity - 1 } // if statement is true
                            : product // if statement is false
                )
            )
        }
    }

    function deleteFromCart(id) {
        // [] if an object meets a condition, add the object to array
        // [product1, product2, product3]
        // [product1, product3]
        setCartProducts(
            cartProducts =>
                cartProducts.filter(currentProduct => {
                    return currentProduct.id !== id;
                })
        )
    }

    function getTotalCost() {
        let totalCost = 0;
        cartProducts.map((cartItem) => {
            const productData = getProductData(cartItem.id);
            totalCost += (productData.price * cartItem.quantity);
        return totalCost;
        });
        return totalCost;
    }

    const contextValue = {
        items: cartProducts,
        getProductQuantity,
        addOneToCart,
        removeOneFromCart,
        deleteFromCart,
        getTotalCost
    }

    return (
        <CartContext.Provider value={contextValue}>
            {children}
        </CartContext.Provider>
    )
}

export default CartProvider;