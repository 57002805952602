import { Container, Row, Col } from 'react-bootstrap';

function ReusableBags() {
    return (
        <Container fluid id="reusableBags" className="reusable-bag my-5 p-md-5 py-3">
            <Row>
                <Col sm={12} md={6}>
                    <h3 className="display-4">Don't Forget Your <br />Reusable Bags</h3>
                    <p className="lead">We are asking members to please bring a reusable bags at pickup.</p>
                    <h4>Option:</h4>
                    <p>Department of Sanitation New York [DSNY] is distributing reusable bags across the city. These bags fold into a built-in pouch with a carabiner clip so they're always handy - and the bags are made of 90% recycled material. To get a bag, take the <a href="https://dsny.force.com/zerowastepledge" rel="noopener noreferrer" target="_blank">Zero Waste Pledge</a>!</p>
                    <p><span className="badge rounded-pill bg-success"><a
                        href="https://dsny.force.com/zerowastepledge"
                        className="h5 p-2 text-white"
                        target="_blank"
                        rel="noopener noreferrer"
                    >The Zero Waste Pledge</a></span></p>
                </Col>
            </Row>
        </Container>
    )
}
export default ReusableBags