import { useState } from "react";
import { Button, Container, Form } from "react-bootstrap";

import { toast } from 'react-toastify';

import { Link } from "react-router-dom";
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, updateProfile } from "firebase/auth";
import { auth, db } from "../../firebase/config";
import { useNavigate } from "react-router-dom";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";



function Login() {

   

    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();




    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    // Login with email/password
    const loginUser = (e) => {
        e.preventDefault();
        setLoading(true);

        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                // const user = userCredential.user;
                // console.log('user',user);
                // ...
                setLoading(false);
                toast.success("Login Successful", {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "dark",
                    autoClose: 500
                });
                navigate("/");
            })
            .catch((error) => {
                // const errorCode = error.code;
                const errorMessage = error.message;
                setLoading(false);
                if (error.code === 'auth/wrong-password') {
                    toast.error("Wrong Password", {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored"
                    });
                } else if (error.code === 'auth/user-not-found') {
                    toast.error("Unable to locate account", {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored"
                    });
                } else {
                    toast.error(errorMessage, {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored"
                    });
                }

            });
    };

    // Login with Google
    const provider = new GoogleAuthProvider();
    const signInWithGoogle = (e) => {
        signInWithPopup(auth, provider)
            .then((result) => {
                // The signed-in user info.
                // const user = result.user;
                // ...

                updateProfile(auth.currentUser, {
                    displayName: result.user.displayName
                });

                const docData = {
                    uid: result.user.uid,
                    email: result.user.email,
                    name: result.user.displayName,
                    timestamp: serverTimestamp()
                };
                const docRef = doc(db, "users", result.user.uid);
                setDoc(docRef, docData, { merge: true });

                toast.success("Google Login Successful ", {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored"
                });
                navigate("/");
            }).catch((error) => {
                // Handle Errors here.
                // const errorCode = error.code;
                const errorMessage = error.message;


                // Display Error Message
                if (error.code === 'auth/popup-closed-by-user') {
                    toast.error("Google Access was Closed", {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        autoClose: 500
                    });
                } else {
                    toast.error(errorMessage, {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored"
                    });
                }
            });

    }
    const formClassName = `ui form ${isLoading ? 'loading' : ''}`;

    return (
        <>
            <Container>
                <h1 className="my-5 text-center">Login</h1>
            </Container>
            <Container className="col-md-6 mx-auto p-5 shadow">
                <Form className={formClassName} onSubmit={loginUser}>
                    <Form.Group className="mb-3" controlId="formGroupEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control size="lg" type="email" placeholder="Enter email" required value={email} onChange={(e) => setEmail(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control size="lg" type="password" placeholder="Password" required value={password} onChange={(e) => setPassword(e.target.value)} />
                    </Form.Group>
                    <div className="d-grid gap-2">
                        <Button variant="success" size="lg" type="submit">
                            Login
                        </Button>
                    </div>
                    <p className="text-center">-- or --</p>
                    <div className="d-grid gap-2">
                        <Button variant="dark" size="lg" onClick={signInWithGoogle}>
                            Sign in with Google
                        </Button>
                    </div>
                </Form>
                <p className='text-center mt-2'>
                    <Link to="/reset">Forgot password?</Link>
                    <br />
                    <i>Don't have account?</i> <strong><Link to="/register">Register</Link></strong>
                </p>
            </Container>
        </>
    );

}

export default Login;
